import * as React from "react";
import WorldMap from "react-svg-worldmap";

function ReactMapChart() {
  const data = [
    { country: "sa", value: 998123 }, // Saudi Arabia
    { country: "qa", value: 8123 }, // Qatar
    { country: "us", value: 23638 }, // United States
    { country: "uk", value: 927392 }, // United Kingdom
    { country: "cn", value: 3929 }, // China
    { country: "au", value: 8123 }, // Australia
  ];

  return (
    <div className="App">
      <WorldMap color="red" value-suffix="people" size="md" data={data} />
    </div>
  );
}

export default ReactMapChart;
