import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import VisitorComparisonPieChart from "../../components/charts/VisitorComparisonPieChart";
import { useSelector } from "react-redux";

function Comparison() {
  const { superExhibitor } = useSelector((state: any) => state.exhibitors);

  return (
    <CustomCard w="100%" minH="330px">
      <Flex align="center" gap="16px">
        <CustomText text="Quota" color="yess.base_black" wrap="nowrap" />
        <Divider bg="#F2F2F2" mt="5px" />
      </Flex>
      <Flex justify="space-between" mt="24px">
        <Flex justify="center" minH="250px" flexDir="column" maxW="175px">
          <Flex gap="24px" flexDir="column" justify="center">
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Assigned User"
                size="12px"
                color="yess.neutral500"
              />
              <Flex align="center" gap="6px">
                <div>
                  <Box
                    minH="12px"
                    minW="12px"
                    bg="yess.secondary_blue"
                    mt="4px"
                  />
                </div>
                <CustomText
                  text={superExhibitor?.assignedUsers || 0}
                  size="14px"
                  color="yess.neutral1000"
                />
              </Flex>
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Total Badges"
                size="12px"
                color="yess.neutral500"
              />
              <Flex align="center" gap="6px">
                <CustomText
                  text={superExhibitor?.utilizedBadges || 0}
                  size="14px"
                  color="yess.neutral1000"
                />
                <div>
                  <Box
                    minH="12px"
                    minW="12px"
                    bg="yess.secondary_gold"
                    mt="4px"
                  />
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {superExhibitor && (
          <VisitorComparisonPieChart
            assignedUsers={superExhibitor?.assignedUsers || 0}
            utilizedBadges={superExhibitor?.utilizedBadges || 0}
          />
        )}
      </Flex>
    </CustomCard>
  );
}

export default Comparison;
