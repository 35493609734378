import React, { useEffect } from "react";
import IconUsers from "../../svgs/IconUsers";
import CustomCard from "../../components/cards/CustomCard";
import { Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import PillButton from "../../components/buttons/PillButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllVisitors } from "../../redux/actions/visitors.actions";

function TotalVisitors() {
  const dispatch: any = useDispatch();
  const { visitors } = useSelector((state: any) => state.visitors);

  useEffect(() => {
    const exhibitorId = localStorage.getItem("exhibitorId");
    dispatch(getAllVisitors(exhibitorId));
    // eslint-disable-next-line
  }, []);

  return (
    <CustomCard minH="280px" minW="300px">
      <Flex justify="space-between" flexDir="column" gap="24px" minH="100%">
        <Flex flexDir="column" gap="24px">
          <Flex align="center" gap="12px">
            <Flex
              bg="yess.secondary_light_gray"
              borderRadius="8px"
              align="center"
              justify="center"
              minH="40px"
              minW="40px"
            >
              <IconUsers />
            </Flex>
            <CustomText text="Total Visitors" color="yess.base_black" />
          </Flex>
          <CustomText
            text={Number(visitors?.length || 0)?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            size="40px"
            color="yess.base_black"
          />
        </Flex>
        <Flex
          borderTop="1px solid #E5E7EB"
          pt="12px"
          align="center"
          justify="space-between"
        >
          <CustomText
            text="Currently Visiting"
            size="14px"
            color="yess.neutral1000"
          />
          <PillButton title={`${visitors?.length} People`} bg="#2EC19E" />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default TotalVisitors;
