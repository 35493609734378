import React from "react";

function BadgeIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.11342 2.40039H11.4268C11.8601 2.40039 12.2468 2.41374 12.5934 2.46041C14.4401 2.66708 14.9401 3.53373 14.9401 5.90706V10.1204C14.9401 12.4937 14.4401 13.3604 12.5934 13.5671C12.2468 13.6137 11.8668 13.6271 11.4268 13.6271H5.11342C4.68009 13.6271 4.29342 13.6137 3.94676 13.5671C2.10009 13.3604 1.6001 12.4937 1.6001 10.1204V5.90706C1.6001 3.53373 2.10009 2.66708 3.94676 2.46041C4.29342 2.41374 4.68009 2.40039 5.11342 2.40039Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.32666 6.61328H11.78"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.7666 10.4736H4.77993H11.7866"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.06991 6.59993H5.07589"
        stroke={color || "#655F5F"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.26961 6.59993H7.2756"
        stroke={color || "#655F5F"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default BadgeIcon;
