const initState = {
  user: null,
  exhibitorId: localStorage.getItem("exhibitorId") || null,
};

const authUser = (state = initState, action: any) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        exhibitorId: action.payload.docId,
        user: { ...action.payload },
      };
    case "LOGIN_FAIL":
      localStorage.clear();
      return {
        ...state,
        exhibitorId: null,
        user: null,
      };
    case "LOGOUT_SUCCESS":
      localStorage.clear();
      return {
        ...state,
        exhibitorId: null,
        user: null,
      };

    default:
      return {
        ...state,
      };
  }
};
export default authUser;
