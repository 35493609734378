import React from "react";

function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 4.66797H14"
        stroke="#4E4949"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M4 8H12"
        stroke="#4E4949"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M6.66675 11.332H9.33341"
        stroke="#4E4949"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default SortIcon;
