import { Box, Input } from "@chakra-ui/react";
import React from "react";

function CustomInput({ type, rightIco, ph, value, onChange, invalid }: any) {
  return (
    <Box pos="relative">
      <Input
        placeholder={ph || ""}
        type={type || "text"}
        fontSize="16px"
        pl="0px"
        pr="40px"
        fontWeight="400"
        color="yess.neutral600"
        bg="transparent"
        _placeholder={{
          color: "yess.neutral100",
        }}
        value={value}
        onChange={onChange}
        border="none"
        borderBottom="1px solid"
        borderColor={invalid ? "#ED3D4E" : "yess.neutral300"}
        borderRadius="0"
        _focus={{
          borderColor: invalid ? "#ED3D4E" : "yess.neutral300",
        }}
        _focusVisible={{
          borderColor: invalid ? "#ED3D4E" : "yess.neutral300",
        }}
        _hover={{
          borderColor: invalid ? "#ED3D4E" : "yess.neutral300",
        }}
        className="aeonik-regular"
      />
      {rightIco && (
        <Box pos="absolute" top="0" right="0" mt="15px">
          {rightIco}
        </Box>
      )}
    </Box>
  );
}

export default CustomInput;
