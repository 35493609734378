import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";

function DashboardLayout() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const newExhibitorId = searchParams.get("exhibitorId");

  const { exhibitorId } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (newExhibitorId) localStorage.setItem("exhibitorId", newExhibitorId);
    if (!exhibitorId && !newExhibitorId) navigate("/auth/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newExhibitorId]);

  return (
    <Flex maxH="100vh">
      <Sidebar />
      <Flex flexDir="column" w="100%">
        <Navbar />
        <Box bg="#F5F5F5" p="24px" overflowY="auto">
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardLayout;
