import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";

function AssignLocation({ payload, setPayload }: any) {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      maxW="666px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading
        text="Assign Location & Quota"
        family="aeonik-bold"
        align="center"
      />
      <CustomText
        text="Assign location and quota to exhibitor"
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="24px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Hall #" size="12px" />
          <FormInput
            ph="Enter Hall #"
            value={payload?.hallNo}
            onChange={(e: any) =>
              setPayload({ ...payload, hallNo: e.target.value })
            }
          />
        </Flex>

        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Stand #" size="12px" />
          <FormInput
            ph="Enter Stand #"
            value={payload?.standNo}
            onChange={(e: any) =>
              setPayload({ ...payload, standNo: e.target.value })
            }
          />
        </Flex>
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Pavilion #" size="12px" />
        <FormInput
          ph="Enter Pavilion #"
          value={payload?.pavillionNo}
          onChange={(e: any) =>
            setPayload({ ...payload, pavillionNo: e.target.value })
          }
        />
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Quota" size="12px" />
        <FormInput
          ph="Enter Quota"
          value={payload?.quota}
          onChange={(e: any) =>
            setPayload({ ...payload, quota: e.target.value })
          }
        />
      </Flex>
    </Flex>
  );
}

export default AssignLocation;
