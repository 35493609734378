import React, { useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Circle,
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import CustomText from "../../components/text/CustomText";
import FilledCircle from "../../assets/images/filled-circle.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useGetExhibitorById, useUpdateKycStatus } from "../../utils/wds.api";
import moment from "moment";

function VisitorDetails() {
  const navigate = useNavigate();

  const toast = useToast();

  const { id } = useParams();

  const { data: visitorInfo, isLoading } = useGetExhibitorById(id);
  const updateStatus = useUpdateKycStatus();

  const [user] = useState(() => {
    const loggedInUser: any = localStorage.getItem("user");
    return loggedInUser ? JSON.parse(loggedInUser) : null;
  });
  const [loadingFor, setLoadingFor] = useState("");
  const [remarks, setRemarks] = useState("");

  return (
    <CustomCard>
      <Flex flexDir="column" gap="24px">
        <Flex align="center" gap="16px">
          <IconArrowLeft
            size={24}
            color="#387DF5"
            cursor="pointer"
            onClick={() => {
              navigate("/visitors");
            }}
          />
          {isLoading ? (
            <SkeletonText noOfLines={1} w="200px" />
          ) : (
            <CustomText
              text={`${
                visitorInfo?.firstName + " " + visitorInfo?.lastName
              } Vetting Profile Detail`}
              size="18px"
              color="yess.base_black"
            />
          )}
        </Flex>
        <Flex gap="24px">
          <Flex flexDir="column" w="100%">
            <Flex align="center" justify="space-between">
              {isLoading ? (
                <Skeleton h="134px" w="134px" borderRadius="8px" />
              ) : (
                <Avatar
                  boxSize="134px"
                  bg="yess.secondary_gold"
                  name={visitorInfo?.firstName + " " + visitorInfo?.lastName}
                  src={visitorInfo?.Avatar}
                  borderRadius="8px"
                />
              )}
              <Flex gap="10px" align="center">
                <CustomText
                  text="Real Image"
                  color="yess.neutral500"
                  mt="-5px"
                />
                <Checkbox size="lg" />
              </Flex>
            </Flex>
            <CustomText
              text="Personal Info"
              color="yess.neutral500"
              mt="16px"
            />
            <Flex
              mt="8px"
              flexDir="column"
              gap="16px"
              p="16px"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.secondary_light_gray"
            >
              <Flex align="center" justify="space-between">
                <CustomText text="Name" color="yess.neutral500" />
                {isLoading ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={
                        visitorInfo?.firstName + " " + visitorInfo?.lastName
                      }
                      color="yess.neutral800"
                    />
                    <Checkbox size="lg" isChecked />
                  </Flex>
                )}
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="Nationality" color="yess.neutral500" />
                {isLoading ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.nationality}
                      color="yess.neutral800"
                    />
                    <Checkbox size="lg" />
                  </Flex>
                )}
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="DOB" color="yess.neutral500" />
                {isLoading ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.dateOfBirth}
                      color="yess.neutral800"
                    />
                    <Checkbox size="lg" />
                  </Flex>
                )}
              </Flex>
              <Flex align="center" justify="space-between">
                <CustomText text="Address" color="yess.neutral500" />
                {isLoading ? (
                  <SkeletonText noOfLines={1} w="150px" />
                ) : (
                  <Flex align="center" gap="40px">
                    <CustomText
                      text={visitorInfo?.personalAddress}
                      color="yess.neutral800"
                    />
                    <Checkbox size="lg" />
                  </Flex>
                )}
              </Flex>
            </Flex>
            <CustomText
              text="Uploaded Documents"
              color="yess.neutral500"
              mt="16px"
            />
            <Flex
              mt="8px"
              gap="24px"
              p="16px"
              borderRadius="12px"
              border="1px solid"
              borderColor="yess.secondary_light_gray"
            >
              {isLoading
                ? new Array(3)?.fill(1)?.map((_, idx) => (
                    <Flex flexDir="column" gap="12px" key={idx}>
                      <Skeleton h="134px" w="134px" borderRadius="8px" />
                      <Flex justify="space-between" gap="16px" align="center">
                        <SkeletonText noOfLines={1} w="134px" />
                      </Flex>
                    </Flex>
                  ))
                : visitorInfo?.documentUrls?.map(
                    (document: any, index: number) => (
                      <Flex key={index} flexDir="column" gap="12px">
                        <Image
                          src={document}
                          alt="passport"
                          height="134px"
                          maxW="150px"
                          objectFit="cover"
                          borderRadius="8px"
                        />
                        <Flex justify="space-between" gap="16px" align="center">
                          <CustomText
                            text={
                              visitorInfo?.documentUrls?.length === 1
                                ? "Passport"
                                : index === 0
                                ? "Iqama (Front)"
                                : "Iqama (Back)"
                            }
                            size="14px"
                            color="yess.neutral1000"
                          />
                          <Checkbox size="lg" isChecked />
                        </Flex>
                      </Flex>
                    )
                  )}
            </Flex>
            <CustomText text="Your Remarks" color="yess.neutral500" mt="16px" />
            <Textarea
              mt="8px"
              p="16px"
              borderRadius="8px"
              border="1px solid #F2F2F2"
              placeholder="Type here..."
              fontSize="14px"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
            <Flex
              mt="16px"
              gap="16px"
              display={
                visitorInfo?.kycStatus === "APPROVED" ||
                visitorInfo?.kycStatus === "REJECTED"
                  ? "none"
                  : "flex"
              }
            >
              <Button
                h="48px"
                w="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                bg="#2EC19E"
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                _hover={{
                  bg: "#2EC19E",
                }}
                isDisabled={loadingFor === "REJECT" && updateStatus.isPending}
                isLoading={loadingFor === "APPROVE" && updateStatus.isPending}
                onClick={() => {
                  setLoadingFor("APPROVE");
                  updateStatus
                    .mutateAsync({
                      id: visitorInfo?.id,
                      status:
                        user?.email === "level01@gmail.com"
                          ? "LEVEL_2_PENDING"
                          : "APPROVED",
                      remarks,
                    })
                    .then((result) => {
                      toast({
                        description: "Visitor Approved Successfully",
                        status: "success",
                        duration: 3000,
                      });
                      window.location.reload();
                      setRemarks("");
                    })
                    .catch((err) => {
                      toast({
                        description: err.message,
                        status: "error",
                        duration: 3000,
                      });
                    });
                }}
              >
                Approve
              </Button>
              <Button
                h="48px"
                w="120px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="12px"
                bg="#ED3D4E"
                fontSize="14px"
                fontWeight="400"
                color="#fff"
                _hover={{
                  bg: "#ED3D4E",
                }}
                isDisabled={loadingFor === "APPROVE" && updateStatus.isPending}
                isLoading={loadingFor === "REJECT" && updateStatus.isPending}
                onClick={() => {
                  setLoadingFor("REJECT");
                  updateStatus
                    .mutateAsync({
                      id: visitorInfo?.id,
                      status: "REJECTED",
                      remarks,
                    })
                    .then((result) => {
                      toast({
                        description: "Visitor KYC Rejected.",
                        status: "success",
                        duration: 3000,
                      });
                      window.location.reload();
                      setRemarks("");
                    })
                    .catch((err) => {
                      toast({
                        description: err.message,
                        status: "error",
                        duration: 3000,
                      });
                    });
                }}
              >
                Reject
              </Button>
            </Flex>
          </Flex>
          <Box w="1px" bg="#f2f2f2" />
          <Flex flexDir="column" gap="32px" w="100%">
            <CustomText
              text="Vetting History"
              size="16px"
              weight="700"
              color="yess.neutral700"
            />
            <Flex gap="16px">
              <Flex flexDir="column" align="center">
                {visitorInfo?.kycStatus !== "REJECTED" ? (
                  <>
                    <Image src={FilledCircle} alt="filled" zIndex="10" />
                    <Box
                      bg={
                        visitorInfo?.kycStatus !== "PENDING"
                          ? "yess.secondary_gold"
                          : "transparent"
                      }
                      border={
                        visitorInfo?.kycStatus === "PENDING" ? "1px dashed" : ""
                      }
                      borderColor="#C1C2C4"
                      h="140px"
                      w="1px"
                      mt="-10px"
                      zIndex="1"
                    />
                    {visitorInfo?.kycStatus === "PENDING" ? (
                      <Circle size="20px" bg="#E4E4E4" />
                    ) : (
                      <Image src={FilledCircle} alt="filled" zIndex="10" />
                    )}
                    <Box
                      h="120px"
                      w="1px"
                      bg={
                        visitorInfo?.kycStatus === "APPROVED"
                          ? "yess.secondary_gold"
                          : "transparent"
                      }
                      border={
                        visitorInfo?.kycStatus !== "APPROVED"
                          ? "1px dashed"
                          : ""
                      }
                      borderColor="#C1C2C4"
                      mt="-10px"
                      zIndex="1"
                    />
                    {visitorInfo?.kycStatus === "LEVEL_2_PENDING" ? (
                      <Circle size="20px" bg="#E4E4E4" />
                    ) : (
                      <Image src={FilledCircle} alt="filled" zIndex="10" />
                    )}
                  </>
                ) : (
                  <>
                    <Image src={FilledCircle} alt="filled" zIndex="10" />
                    <Box
                      h="120px"
                      w="1px"
                      bg="yess.secondary_gold"
                      mt="-10px"
                      zIndex="1"
                    />
                    <Image src={FilledCircle} alt="filled" zIndex="10" />
                  </>
                )}
              </Flex>
              <Flex flexDir="column">
                <Flex flexDir="column" gap="8px">
                  <CustomText
                    text={
                      moment(visitorInfo?.user?.createdAt).format(
                        "MMM DD, YYYY hh:mm A"
                      ) || ""
                    }
                    size="12px"
                    color="yess.secondary_gold"
                  />
                  <CustomText
                    text="User Action"
                    size="16px"
                    color="yess.neutral700"
                  />
                  <CustomText
                    text="Personal info updated by the user."
                    size="14px"
                    color="yess.neutral500"
                  />
                </Flex>
                {visitorInfo?.kycStatus !== "REJECTED" ? (
                  <>
                    <Flex flexDir="column" gap="8px" mt="45px">
                      {(visitorInfo?.kycStatus === "LEVEL_2_PENDING" ||
                        visitorInfo?.kycStatus === "APPROVED") && (
                        <CustomText
                          text={
                            moment(visitorInfo?.updatedAt).format(
                              "MMM DD, YYYY hh:mm A"
                            ) || ""
                          }
                          size="12px"
                          color="yess.secondary_gold"
                        />
                      )}
                      <Flex gap="10px" align="center">
                        <CustomText
                          text="Admin Action"
                          size="16px"
                          color="yess.neutral700"
                        />
                        {(visitorInfo?.kycStatus === "LEVEL_2_PENDING" ||
                          visitorInfo?.kycStatus === "APPROVED") && (
                          <Button
                            h="28px"
                            w="102px"
                            display="flex"
                            gap="8px"
                            justifyContent="center"
                            alignItems="center"
                            bg="#2EC19E"
                            borderRadius="8px"
                            fontSize="12px"
                            fontWeight="400"
                            color="#fff"
                            _hover={{
                              bg: "#2EC19E",
                            }}
                          >
                            Completed
                            <Box>
                              <IconCheck color="#fff" size="14px" />
                            </Box>
                          </Button>
                        )}
                      </Flex>
                      <CustomText
                        text="Visitor Level 01 KYC verified."
                        size="14px"
                        color="yess.neutral500"
                      />
                    </Flex>
                    <Flex flexDir="column" gap="8px" mt="65px">
                      {visitorInfo?.kycStatu === "APPROVED" && (
                        <CustomText
                          text={
                            moment(visitorInfo?.updatedAt).format(
                              "MMM DD, YYYY hh:mm A"
                            ) || ""
                          }
                          size="12px"
                          color="yess.secondary_gold"
                        />
                      )}
                      <Flex gap="10px" align="center">
                        <CustomText
                          text="User Action"
                          size="16px"
                          color="yess.neutral700"
                        />
                        {visitorInfo?.kycStatus === "APPROVED" && (
                          <Button
                            h="28px"
                            w="102px"
                            display="flex"
                            gap="8px"
                            justifyContent="center"
                            alignItems="center"
                            bg="#2EC19E"
                            borderRadius="8px"
                            fontSize="12px"
                            fontWeight="400"
                            color="#fff"
                            _hover={{
                              bg: "#2EC19E",
                            }}
                          >
                            Completed
                            <Box>
                              <IconCheck color="#fff" size="14px" />
                            </Box>
                          </Button>
                        )}
                      </Flex>
                      <CustomText
                        text="Visitor Level 2 KYC completed."
                        size="14px"
                        color="yess.neutral500"
                      />
                    </Flex>
                  </>
                ) : (
                  <Flex flexDir="column" gap="8px" mt="50px">
                    <CustomText
                      text={
                        moment(visitorInfo?.updatedAt).format(
                          "MMM DD, YYYY hh:mm A"
                        ) || ""
                      }
                      size="12px"
                      color="yess.secondary_gold"
                    />
                    <Flex gap="10px" align="center">
                      <CustomText
                        text="Admin Action"
                        size="16px"
                        color="yess.neutral700"
                      />
                      <Circle size="4px" bg="#387DF5" />
                      <CustomText
                        text={`Admin: ${
                          user?.email === "level01@gmail.com"
                            ? "Level 01"
                            : "Omar Kareem"
                        }`}
                        size="12px"
                        color="yess.secondary_blue"
                      />
                      <Button
                        h="28px"
                        w="102px"
                        display="flex"
                        gap="8px"
                        justifyContent="center"
                        alignItems="center"
                        bg="#ED3D4E"
                        borderRadius="8px"
                        fontSize="12px"
                        fontWeight="400"
                        color="#fff"
                        _hover={{
                          bg: "#ED3D4E",
                        }}
                      >
                        Rejected
                      </Button>
                    </Flex>
                    <CustomText
                      text="Reviewed submitted personal information."
                      size="14px"
                      color="yess.neutral500"
                    />
                    <Flex p="16px" borderRadius="8px" bg="#f2f2f2" w="400px">
                      <CustomText
                        text={visitorInfo?.remarks || ""}
                        size="14px"
                        color="yess.neutral700"
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default VisitorDetails;
