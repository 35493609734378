import { Button } from "@chakra-ui/react";
import React from "react";

function PillButton({
  title,
  borderColor,
  bg,
  shadow,
  maxW,
  h,
  size,
  color,
}: any) {
  return (
    <Button
      border="1px solid"
      borderColor={borderColor || "#FAFAFA"}
      bg={bg || "yess.secondary_blue"}
      boxShadow={shadow || "0px 4px 9px 0px rgba(56, 125, 245, 0.40)"}
      maxW={maxW || "155px"}
      w="100%"
      h={h || "40px"}
      fontSize={size || "16px"}
      fontWeight="700"
      color={color || "#fff"}
      className="aeonik-regular"
      borderRadius="999px"
      _hover={{
        bg: bg || "yess.secondary_blue",
      }}
    >
      {title}
    </Button>
  );
}

export default PillButton;
