import React from "react";

function VisitorsIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99984 7.99967C9.84079 7.99967 11.3332 6.50729 11.3332 4.66634C11.3332 2.82539 9.84079 1.33301 7.99984 1.33301C6.15889 1.33301 4.6665 2.82539 4.6665 4.66634C4.6665 6.50729 6.15889 7.99967 7.99984 7.99967Z"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
        stroke={color || "#655F5F"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default VisitorsIcon;
