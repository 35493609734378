import React from "react";

function IconBadge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M11.0162 15.3324L11.1704 18.458L8.00883 16.2609L4.82959 18.4601L4.98383 15.3324H3.64861L3.41848 20H4.94761L8.00708 17.8838L11.0524 20H12.5816L12.3515 15.3324H11.0162ZM14.6434 5.78533L14.5519 3.78269L12.8635 2.70219L11.7828 1.01357L9.78009 0.921887L8.00004 0L6.21998 0.922095L4.2173 1.01378L3.13659 2.70219L1.44814 3.78269L1.35671 5.78533L0.43457 7.56542L1.35671 9.34556L1.44839 11.3482L3.13659 12.4287L4.21714 14.1173L6.21982 14.2088L8.00004 15.1309L9.78009 14.2088L11.7828 14.1173L12.8633 12.4287L14.5519 11.3483L14.6434 9.34573L15.5655 7.56559L14.6434 5.78533ZM13.3245 8.99216L13.2511 10.5972L11.8977 11.4632L11.0317 12.8166L9.42656 12.89L8.00004 13.629L6.57334 12.89L4.96824 12.8166L4.10224 11.4632L2.74902 10.5972L2.67571 8.99216L1.93636 7.56542L2.67555 6.13898L2.74902 4.53367L4.10224 3.66767L4.96824 2.31432L6.57334 2.24098L8.00004 1.50192L9.42673 2.24093L11.0318 2.31428L11.8978 3.66763L13.2511 4.53367L13.3244 6.13877L14.0637 7.56542L13.3245 8.99216Z"
        fill="black"
      />
    </svg>
  );
}

export default IconBadge;
