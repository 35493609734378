import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers";
import { thunk } from "redux-thunk";

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  //@ts-ignore
  composeEnhancers(applyMiddleware(thunk))
);
