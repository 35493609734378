import React from "react";

function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.91017 11.192V8.56522H14.0974C14.2485 8.56522 14.3933 8.50523 14.5001 8.39845C14.6068 8.29167 14.6668 8.14685 14.6668 7.99584C14.6668 7.84483 14.6068 7.70001 14.5001 7.59323C14.3933 7.48645 14.2485 7.42646 14.0974 7.42646H5.91017V4.8073C5.91102 4.73567 5.89028 4.66544 5.85065 4.60575C5.81103 4.54607 5.75435 4.49969 5.68801 4.47266C5.62166 4.44563 5.54872 4.4392 5.47866 4.4542C5.40861 4.4692 5.3447 4.50494 5.29524 4.55678L1.44004 7.74531C1.4063 7.77851 1.3795 7.8181 1.36121 7.86176C1.34292 7.90543 1.3335 7.95229 1.3335 7.99963C1.3335 8.04697 1.34292 8.09384 1.36121 8.1375C1.3795 8.18117 1.4063 8.22075 1.44004 8.25396L5.29524 11.4425C5.3447 11.4943 5.40861 11.5301 5.47866 11.5451C5.54872 11.5601 5.62166 11.5536 5.68801 11.5266C5.75435 11.4996 5.81103 11.4532 5.85065 11.3935C5.89028 11.3338 5.91102 11.2636 5.91017 11.192Z"
        fill="#C4CADA"
      />
    </svg>
  );
}

export default ArrowLeft;
