import React from "react";

function IconTrash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M7.686 5.15405L7.47308 10.6924M4.52669 10.6924L4.31378 5.15405M10.4478 3.17871C10.6578 3.21071 10.8675 3.24476 11.0767 3.28087M10.4478 3.17871L9.79056 11.7219C9.76374 12.0697 9.6066 12.3946 9.35058 12.6315C9.09456 12.8685 8.75853 13.0001 8.40967 13H3.5901C3.24125 13.0001 2.90521 12.8685 2.64919 12.6315C2.39317 12.3946 2.23604 12.0697 2.20922 11.7219L1.552 3.17871M10.4478 3.17871C9.73756 3.07128 9.02367 2.9898 8.30752 2.93441M1.552 3.17871C1.34196 3.2103 1.13232 3.24415 0.923096 3.28025M1.552 3.17871C2.26221 3.07128 2.9761 2.9898 3.69225 2.93441M8.30752 2.93441V2.37074C8.30752 1.6446 7.74753 1.03908 7.0214 1.01631C6.34057 0.994564 5.65921 0.994564 4.97838 1.01631C4.25224 1.03908 3.69225 1.64522 3.69225 2.37074V2.93441M8.30752 2.93441C6.77136 2.8165 5.22842 2.8165 3.69225 2.93441"
        stroke="#4E4949"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconTrash;
