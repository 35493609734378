import React from "react";

function IconCSV() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M2.04163 7.58366V7.11466C2.04163 5.38391 2.04163 4.51883 2.31521 3.82699C2.75504 2.71574 3.68488 1.83958 4.86438 1.42483C5.59704 1.16699 6.51463 1.16699 8.35213 1.16699C9.40096 1.16699 9.92596 1.16699 10.3448 1.31458C11.0185 1.55141 11.55 2.05191 11.8014 2.68716C11.9577 3.08208 11.9577 3.57674 11.9577 4.56549V7.58366"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.04163 7.00033C2.04163 6.48468 2.24647 5.99015 2.61108 5.62553C2.9757 5.26092 3.47023 5.05608 3.98588 5.05608C4.37438 5.05608 4.83229 5.12374 5.20971 5.02283C5.37462 4.97844 5.52497 4.89149 5.64568 4.77068C5.76639 4.64987 5.85322 4.49944 5.89746 4.33449C5.99838 3.95708 5.93071 3.49916 5.93071 3.11066C5.93086 2.59511 6.13577 2.10074 6.50037 1.73624C6.86497 1.37175 7.35941 1.16699 7.87496 1.16699M4.37496 10.0453C4.34288 9.35116 3.86279 9.33366 3.21121 9.33366C2.20788 9.33366 2.04163 9.57049 2.04163 10.5003V11.667C2.04163 12.5968 2.20788 12.8337 3.21121 12.8337C3.86279 12.8337 4.34288 12.8162 4.37496 12.122M11.9583 9.33366L10.9217 12.0724C10.7292 12.5799 10.6335 12.8337 10.4813 12.8337C10.3296 12.8337 10.2328 12.5799 10.0409 12.0724L9.00429 9.33366M7.51096 9.33366H6.82263C6.54671 9.33366 6.40904 9.33366 6.30054 9.37799C5.93071 9.52966 5.93596 9.88491 5.93596 10.2087C5.93596 10.5324 5.93071 10.8882 6.30054 11.0393C6.40904 11.0837 6.54671 11.0837 6.82204 11.0837C7.09738 11.0837 7.23504 11.0837 7.34354 11.128C7.71338 11.2797 7.70813 11.6349 7.70813 11.9587C7.70813 12.2824 7.71338 12.6382 7.34354 12.7893C7.23504 12.8337 7.09738 12.8337 6.82204 12.8337H6.07246"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconCSV;
