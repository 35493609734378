import React from "react";

function RotateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M4.55504 2.5402C4.99004 2.4102 5.47004 2.3252 6.00004 2.3252C8.39504 2.3252 10.335 4.2652 10.335 6.6602C10.335 9.0552 8.39504 10.9952 6.00004 10.9952C3.60504 10.9952 1.66504 9.0552 1.66504 6.6602C1.66504 5.7702 1.93504 4.9402 2.39504 4.2502"
        stroke="#387DF5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.93506 2.66049L5.38006 1.00049"
        stroke="#387DF5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.93506 2.66016L5.62006 3.89016"
        stroke="#387DF5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default RotateIcon;
