import React from "react";

function IconCalendar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.33333 1.75V3.625"
        stroke="#0B0A0A"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6667 1.75V3.625"
        stroke="#0B0A0A"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.33333 6.18164H13.6667"
        stroke="#0B0A0A"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 5.8125V11.125C14 13 13 14.25 10.6667 14.25H5.33333C3 14.25 2 13 2 11.125V5.8125C2 3.9375 3 2.6875 5.33333 2.6875H10.6667C13 2.6875 14 3.9375 14 5.8125Z"
        stroke="#0B0A0A"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4631 9.0625H10.4691"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4631 10.9375H10.4691"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99699 9.0625H8.00298"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99699 10.9375H8.00298"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.52954 9.0625H5.53553"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.52954 10.9375H5.53553"
        stroke="#0B0A0A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconCalendar;
