import { Flex } from "@chakra-ui/react";
import React from "react";
import TotalExhibitos from "./TotalExhibitos";
import TotalVisitors from "./TotalVisitors";
import TopCountries from "./TopCountries";
import BadgesStatus from "./BadgesStats";
import Comparison from "./Comparison";

function Dashboard() {
  return (
    <>
      <Flex gap="24px">
        <TotalExhibitos />
        <TotalVisitors />
        <Comparison />
      </Flex>
      <Flex mt="24px" gap="24px">
        <TopCountries />
        <BadgesStatus />
      </Flex>
    </>
  );
}

export default Dashboard;
