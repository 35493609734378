import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.33374 1.33252H6.00041C2.66707 1.33252 1.33374 2.66585 1.33374 5.99919V9.99918C1.33374 13.3325 2.66707 14.6658 6.00041 14.6658H10.0004C13.3337 14.6658 14.6671 13.3325 14.6671 9.99918V8.66585"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6933 2.01497L5.43992 7.2683C5.23992 7.4683 5.03992 7.86163 4.99992 8.1483L4.71325 10.155C4.60659 10.8816 5.11992 11.3883 5.84659 11.2883L7.85325 11.0016C8.13325 10.9616 8.52659 10.7616 8.73325 10.5616L13.9866 5.3083C14.8933 4.40163 15.3199 3.3483 13.9866 2.01497C12.6533 0.681633 11.5999 1.1083 10.6933 2.01497Z"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.9397 2.76611C10.3864 4.35945 11.633 5.60611 13.233 6.05945"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
