import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";

function Documents({ payload, setPayload }: any) {
  return (
    <Flex
      flexDir="column"
      gap="24px"
      maxW="400px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Documents" family="aeonik-bold" align="center" />
      <CustomText
        text="Enter documents details"
        align="center"
        color="yess.neutral600"
      />
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="IQAMA/Resident ID" size="12px" />
        <FormInput
          ph="Enter IQAMA/Resident ID"
          value={payload?.iqamaID}
          onChange={(e: any) =>
            setPayload({ ...payload, iqamaID: e.target.value })
          }
        />
      </Flex>
      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Passport Number" size="12px" />
        <FormInput
          ph="Enter Passport Number"
          value={payload?.passportNo}
          onChange={(e: any) =>
            setPayload({ ...payload, passportNo: e.target.value })
          }
        />
      </Flex>
    </Flex>
  );
}

export default Documents;
