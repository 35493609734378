import React from "react";

function ImportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M1 7C1 8.5913 1.63214 10.1174 2.75736 11.2426C3.88258 12.3679 5.4087 13 7 13C8.5913 13 10.1174 12.3679 11.2426 11.2426C12.3679 10.1174 13 8.5913 13 7"
        stroke="#4E4949"
        stroke-width="1.125"
        stroke-linecap="round"
      />
      <path
        d="M7 1V8.5M7 8.5L9.25 6.25M7 8.5L4.75 6.25"
        stroke="#4E4949"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ImportIcon;
