import { Text } from "@chakra-ui/react";
import React from "react";

function CustomHeading({
  text,
  cursor,
  size,
  weight,
  color,
  align,
  transform,
  onClick,
  family,
  lh,
}: any) {
  return (
    <Text
      cursor={cursor || "default"}
      fontSize={size || "32px"}
      fontWeight={weight || "700"}
      color={color || "yess.base_black"}
      textAlign={align || "left"}
      textTransform={transform || "none"}
      onClick={onClick}
      lineHeight={lh || "normal"}
    >
      <span className={family || "gotham-bold"}>{text}</span>
    </Text>
  );
}

export default CustomHeading;
