import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M11.0482 10.0737L14 13.0248L13.0248 14L10.0737 11.0482C8.9757 11.9285 7.60993 12.4072 6.20262 12.4052C2.77877 12.4052 0 9.62646 0 6.20262C0 2.77877 2.77877 0 6.20262 0C9.62646 0 12.4052 2.77877 12.4052 6.20262C12.4072 7.60993 11.9285 8.9757 11.0482 10.0737ZM9.66575 9.56237C10.5402 8.66279 11.0286 7.45718 11.0269 6.20262C11.0269 3.53756 8.86768 1.37836 6.20262 1.37836C3.53756 1.37836 1.37836 3.53756 1.37836 6.20262C1.37836 8.86768 3.53756 11.0269 6.20262 11.0269C7.45718 11.0286 8.66279 10.5402 9.56237 9.66575L9.66575 9.56237Z"
        fill="#4E4949"
      />
    </svg>
  );
}

export default SearchIcon;
