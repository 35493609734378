import { Button, Flex, Spinner, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import CustomPhoneInput from "../../components/form/PhoneInput";
import IconGreenCheck from "../../svgs/IconGreenCheck";
import { useSendEmail } from "../../utils/wds.api";

function SendInvite({ payload, setPayload }: any) {
  const sendEmail = useSendEmail();

  const toast = useToast();

  const [inviteSent, setInviteSent] = useState({
    email: false,
    phone: false,
    whatsapp: false,
  });

  return (
    <Flex
      flexDir="column"
      gap="24px"
      maxW="497px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Send invite" family="aeonik-bold" align="center" />
      <CustomText
        text="Send invite with given options below"
        align="center"
        color="yess.neutral600"
      />
      <Flex align="flex-end" gap="16px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Email" size="12px" />
          <FormInput
            ph="Enter Email"
            value={payload.inviteEmail}
            onChange={(e: any) => {
              setPayload({
                ...payload,
                inviteEmail: e.target.value,
              });
            }}
          />
        </Flex>
        <Button
          h="38px"
          w="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          bg={inviteSent?.email ? "#D8FFF6" : "yess.secondary_gold"}
          _hover={{
            bg: inviteSent?.email ? "#D8FFF6" : "yess.secondary_gold",
          }}
          isDisabled={inviteSent?.email || sendEmail?.isPending}
          onClick={() => {
            sendEmail
              ?.mutateAsync({
                from: "noreply@yess.com",
                to: [payload.inviteEmail],
                subject: `You're Invited to join WDS Event! Here's Your Temporary Login Information`,
                html: `
                <div>
                <p>We are excited to invite you to our upcoming event!</p>
                <p>To access the event portal and confirm your participation, please log in with the following temporary credentials:</p>
                <p><strong>Email:</strong> ${payload?.inviteEmail}</p>
                <p><strong>Password:</strong> Qwertyuiop@123</p>
                <p><strong>Exhibitor Panel:</strong>
        <a href="https://yess-exhibitors.pages.dev/auth/login">Click Here</a>
        </p>
                </div>
              `,
                body: "",
              })
              .then((result) => {
                setInviteSent({ ...inviteSent, email: true });
                toast({
                  description: "The invite has been sent successfully",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
              })
              .catch((err) => {
                toast({
                  description: "An error occurred while sending the invite",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              });
          }}
        >
          {sendEmail?.isPending ? (
            <Spinner size="sm" />
          ) : inviteSent?.email ? (
            <IconGreenCheck />
          ) : (
            <CustomText text="Send" size="12px" color="#fff" />
          )}
        </Button>
      </Flex>
      <Flex align="flex-end" gap="16px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Phone" size="12px" />
          <CustomPhoneInput
            value={payload.invitePhone}
            onChange={(e: any) => {
              setPayload({
                ...payload,
                invitePhone: e,
              });
            }}
          />
        </Flex>
        <Button
          h="38px"
          w="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          bg={inviteSent?.phone ? "#D8FFF6" : "yess.secondary_gold"}
          _hover={{
            bg: inviteSent?.phone ? "#D8FFF6" : "yess.secondary_gold",
          }}
          onClick={() => setInviteSent({ ...inviteSent, phone: true })}
        >
          {inviteSent?.phone ? (
            <IconGreenCheck />
          ) : (
            <CustomText text="Send" size="12px" color="#fff" />
          )}{" "}
        </Button>
      </Flex>
      <Flex align="flex-end" gap="16px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Whats App" size="12px" />
          <CustomPhoneInput
            value={payload.inviteWhatsApp}
            onChange={(e: any) => {
              setPayload({
                ...payload,
                inviteWhatsApp: e,
              });
            }}
          />
        </Flex>
        <Button
          h="38px"
          w="80px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="8px"
          bg={inviteSent?.whatsapp ? "#D8FFF6" : "yess.secondary_gold"}
          _hover={{
            bg: inviteSent?.whatsapp ? "#D8FFF6" : "yess.secondary_gold",
          }}
          onClick={() => setInviteSent({ ...inviteSent, whatsapp: true })}
        >
          {inviteSent?.whatsapp ? (
            <IconGreenCheck />
          ) : (
            <CustomText text="Send" size="12px" color="#fff" />
          )}{" "}
        </Button>
      </Flex>
    </Flex>
  );
}

export default SendInvite;
