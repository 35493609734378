import React from "react";

function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
        stroke="#4E4949"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00014 13.5138C10.3535 13.5138 12.5468 12.1271 14.0735 9.72714C14.6735 8.78714 14.6735 7.20714 14.0735 6.26714C12.5468 3.86714 10.3535 2.48047 8.00014 2.48047C5.64681 2.48047 3.45347 3.86714 1.92681 6.26714C1.32681 7.20714 1.32681 8.78714 1.92681 9.72714C3.45347 12.1271 5.64681 13.5138 8.00014 13.5138Z"
        stroke="#4E4949"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
