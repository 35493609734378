import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useSendEmail = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`api/sendEmail`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useCreateAd = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`api/create-ad`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getAds"] });
      return response;
    },
  });
};

export const useGetExhibitors = () =>
  useQuery({
    queryKey: ["getExhibitors"],
    queryFn: async () => {
      const response = await client(`api/get-exhibitors`);
      return response;
    },
  });

export const useGetExhibitorById = (id: string | undefined) =>
  useQuery({
    queryKey: ["getExhibitorById", id],
    queryFn: async () => {
      const response = await client(`api/get-exhibitor/${id}`);
      return response;
    },
    enabled: !!id,
  });

export const useGetAds = () =>
  useQuery({
    queryKey: ["getAds"],
    queryFn: async () => {
      const response = await client(`api/get-ads`);
      return response;
    },
  });

export const useUploadMedia = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`media-upload/mediaFiles/ad-images`, {
        method: "POST",
        data: payload,
      });
      if (response?.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetVisitors = () =>
  useQuery({
    queryKey: ["getVisitors"],
    queryFn: async () => {
      const response = await client(`api/get-exhibitors`);
      return response;
    },
  });

export const useUpdateKycStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(
        `api/updateExhibitorKycStatus/${payload?.id}?status=${payload?.status}`,
        {
          method: "POST",
          data: { remarks: payload?.remarks },
        }
      );
      queryClient.invalidateQueries({ queryKey: ["getVisitors"] });
      queryClient.invalidateQueries({
        queryKey: ["getVisitorById", payload?.id],
      });
      if (response?.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};
