import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomHeading from "../../components/text/CustomHeading";
import CustomText from "../../components/text/CustomText";
import FormInput from "../../components/form/FormInput";
import CustomPhoneInput from "../../components/form/PhoneInput";

function PersonalInfo({ payload, setPayload }: any) {
  return (
    <Flex
      flexDir="column"
      gap="22px"
      maxW="666px"
      w="100%"
      mx="auto"
      minH="521px"
    >
      <CustomHeading text="Personal Info" family="aeonik-bold" align="center" />
      <CustomText
        text="Enter Personal Info of the exhibitor"
        align="center"
        color="yess.neutral600"
      />
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="First Name" size="12px" />
          <FormInput
            ph="Enter First Name"
            maxW="322px"
            value={payload?.firstName}
            onChange={(e: any) =>
              setPayload({ ...payload, firstName: e.target.value })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Last Name" size="12px" />
          <FormInput
            ph="Enter Last Name"
            maxW="322px"
            value={payload?.lastName}
            onChange={(e: any) =>
              setPayload({ ...payload, lastName: e.target.value })
            }
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Email" size="12px" />
          <FormInput
            ph="Enter Email"
            maxW="322px"
            value={payload?.personalEmail}
            onChange={(e: any) =>
              setPayload({
                ...payload,
                personalEmail: e.target.value,
                inviteEmail: e.target.value,
              })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Job Title" size="12px" />
          <FormInput
            ph="Enter Job Title"
            maxW="322px"
            value={payload?.jobTitle}
            onChange={(e: any) =>
              setPayload({
                ...payload,
                jobTitle: e.target.value,
              })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="DOB" size="12px" />
          <FormInput
            ph="Enter DOB"
            maxW="322px"
            type="date"
            value={payload?.dateOfBirth}
            onChange={(e: any) =>
              setPayload({ ...payload, dateOfBirth: e.target.value })
            }
            // rightIco={<IconCalendar />}
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Nationality" size="12px" />
          <FormInput
            ph="Enter Nationality"
            maxW="322px"
            value={payload?.nationality}
            onChange={(e: any) =>
              setPayload({ ...payload, nationality: e.target.value })
            }
            // rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Phone Number" size="12px" />
          <CustomPhoneInput
            value={payload?.personalPhone}
            onChange={(e: any) => {
              setPayload({ ...payload, personalPhone: e, invitePhone: e });
            }}
          />
        </Flex>
      </Flex>
      <Flex gap="22px">
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Country" size="12px" />
          <FormInput
            ph="Enter Country"
            maxW="322px"
            value={payload?.personalCountry}
            onChange={(e: any) =>
              setPayload({ ...payload, personalCountry: e.target.value })
            }
            // rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="City" size="12px" />
          <FormInput
            ph="Select City"
            maxW="322px"
            value={payload?.personalCity}
            onChange={(e: any) =>
              setPayload({ ...payload, personalCity: e.target.value })
            }
            // rightIco={<IconChevDown />}
          />
        </Flex>
        <Flex flexDir="column" gap="11px" w="100%">
          <CustomText text="Zip Code" size="12px" />
          <FormInput
            ph="Enter Zip Code"
            maxW="322px"
            value={payload?.personalZipCode}
            onChange={(e: any) =>
              setPayload({ ...payload, personalZipCode: e.target.value })
            }
          />
        </Flex>
      </Flex>

      <Flex flexDir="column" gap="11px" w="100%">
        <CustomText text="Address" size="12px" />
        <FormInput
          ph="Enter Address"
          value={payload?.personalAddress}
          onChange={(e: any) =>
            setPayload({ ...payload, personalAddress: e.target.value })
          }
        />
      </Flex>
    </Flex>
  );
}

export default PersonalInfo;
