import React, { useState } from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Divider, Flex, useToast } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import CustomButton from "../../components/buttons/CustomButton";
import PersonalInfo from "./PersonalInfo";
import AssignLocation from "./AssignLocation";
import Documents from "./Documents";
import SendInvite from "./SendInvite";
import Congrats from "./Congrats";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addExhibitor } from "../../redux/actions/exhibitors.actions";

function CreateExhibitor() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { exhibitorLoading } = useSelector((state: any) => state.exhibitors);

  const toast = useToast();

  const [activeStep, setActiveStep] = useState(1);
  const [payload, setPayload] = useState({
    jobTitle: "",
    firstName: "",
    lastName: "",
    personalEmail: "",
    dateOfBirth: "",
    nationality: "",
    personalPhone: "",
    personalCountry: "",
    personalCity: "",
    personalAddress: "",
    personalZipCode: "",
    hallNo: "",
    standNo: "",
    pavillionNo: "",
    quota: "",
    iqamaID: "",
    passportNo: "",
    inviteEmail: "",
    invitePhone: "",
    inviteWhatsApp: "",
  });

  const checkActiveStep = (index: number) => {
    return activeStep === index + 1 || activeStep > index;
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return <PersonalInfo payload={payload} setPayload={setPayload} />;
      case 2:
        return <AssignLocation payload={payload} setPayload={setPayload} />;
      case 3:
        return <Documents payload={payload} setPayload={setPayload} />;
      case 4:
        return <SendInvite payload={payload} setPayload={setPayload} />;
      case 5:
        return <Congrats />;
      default:
        return <PersonalInfo payload={payload} setPayload={setPayload} />;
    }
  };

  const handleSaveExhibitor = () => {
    const exhibitorId = localStorage.getItem("exhibitorId");
    const newExhibitor = {
      exhibitorId: exhibitorId || "",
      personalInfo: [
        {
          name: `${payload.firstName} ${payload.lastName}`,
          email: payload.personalEmail,
          position: payload.jobTitle,
          img: "",
        },
      ],
      location: {
        hall: payload.hallNo,
        stand: payload.standNo,
        pavillion: payload.pavillionNo,
        quota: payload.quota,
      },
      assignedUsers: 0,
      utilizedBadges: 0,
    };
    dispatch(addExhibitor(newExhibitor));
  };

  return (
    <CustomCard>
      <Flex flexDir="column" gap="35px">
        <CustomText
          text="Create Exhibitors"
          size="24px"
          weight="700"
          color="yess.base_black"
        />
        <Flex align="center" justify="center">
          {STEPS.map((step, index) => (
            <Flex align="center" gap="16px" key={index}>
              <Flex align="center" gap="12px">
                <Flex
                  align="center"
                  justify="center"
                  minH="32px"
                  minW="32px"
                  borderRadius="999px"
                  bg={
                    checkActiveStep(index) ? "yess.secondary_gold" : "#221F1F"
                  }
                >
                  <CustomText
                    text={index + 1}
                    size="12px"
                    color={
                      checkActiveStep(index)
                        ? "yess.base_black"
                        : "yess.neutral300"
                    }
                  />
                </Flex>
                <CustomText
                  text={step.title}
                  wrap="nowrap"
                  color={
                    checkActiveStep(index)
                      ? "yess.base_black"
                      : "yess.neutral600"
                  }
                />
              </Flex>
              <Divider
                maxW="110px"
                minW="80px"
                opacity="1"
                h="1px"
                border="none"
                display={index === STEPS.length - 1 ? "none" : "block"}
                me="16px"
                bg={
                  checkActiveStep(index)
                    ? "yess.secondary_gold"
                    : "yess.neutral600"
                }
              />
            </Flex>
          ))}
        </Flex>
        {renderSteps()}
        <Flex
          borderTop="1px solid #F2F2F2"
          pt="16px"
          justify="end"
          gap="10px"
          mt="20px"
          display={activeStep === 5 ? "none" : "flex"}
        >
          <CustomButton
            btnTitle="Back"
            maxW="160px"
            bg="yess.neutral900"
            onClick={() => {
              if (activeStep === 1) {
                navigate("/sub-exhibitors");
              } else {
                setActiveStep(activeStep - 1);
              }
            }}
          />
          <CustomButton
            btnTitle="Next"
            maxW="160px"
            bg="yess.secondary_gold"
            isLoading={exhibitorLoading}
            onClick={() => {
              const stepValidation = [
                () =>
                  payload.firstName &&
                  payload.lastName &&
                  payload.personalEmail &&
                  payload.dateOfBirth &&
                  payload.nationality &&
                  payload.personalPhone &&
                  payload.personalCountry &&
                  payload.personalCity &&
                  payload.personalAddress &&
                  payload.personalZipCode,
                () => payload.hallNo && payload.standNo && payload.pavillionNo,
                () => payload.iqamaID && payload.passportNo,
                () => payload.inviteEmail || payload.invitePhone,
              ];
              const canProceed =
                activeStep <= stepValidation.length
                  ? stepValidation[activeStep - 1]()
                  : true;

              if (canProceed) {
                if (activeStep === 4) {
                  handleSaveExhibitor();
                }
                setActiveStep(activeStep + 1);
              } else {
                toast({
                  description: "Please fill all the required fields",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
          />
        </Flex>
      </Flex>
    </CustomCard>
  );
}

const STEPS = [
  {
    title: "Personal Info",
  },
  {
    title: "Location & Quota",
  },
  {
    title: "Documents",
  },
  {
    title: "Send Invite",
  },
];

export default CreateExhibitor;
