import React from "react";

function ExhibitorsIcon({ color }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12 12.573H11.4933C10.96 12.573 10.4533 12.7797 10.08 13.153L8.93998 14.2797C8.41998 14.793 7.57334 14.793 7.05334 14.2797L5.91333 13.153C5.54 12.7797 5.02667 12.573 4.5 12.573H4C2.89333 12.573 2 11.6863 2 10.593V3.31301C2 2.21967 2.89333 1.33301 4 1.33301H12C13.1067 1.33301 14 2.21967 14 3.31301V10.5863C14 11.6797 13.1067 12.573 12 12.573Z"
        stroke={color || "#4E4949"}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.04671 5.96719C8.02005 5.96719 7.98003 5.96719 7.9467 5.96719C7.2467 5.94052 6.69336 5.37385 6.69336 4.66719C6.69336 3.94719 7.27337 3.36719 7.99337 3.36719C8.71337 3.36719 9.29338 3.95385 9.29338 4.66719C9.30004 5.37385 8.74671 5.94719 8.04671 5.96719Z"
        stroke={color || "#4E4949"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.16646 7.97363C5.2798 8.56697 5.2798 9.53363 6.16646 10.127C7.17313 10.8003 8.82646 10.8003 9.83313 10.127C10.7198 9.53363 10.7198 8.56697 9.83313 7.97363C8.82646 7.30697 7.1798 7.30697 6.16646 7.97363Z"
        stroke={color || "#4E4949"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ExhibitorsIcon;
