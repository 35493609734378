// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    yess: {
      primary_blue: "#001C70",
      primary_dark_blue: "#001C70",
      primary_gray: "#C1C2C4",
      secondary_blue: "#387DF5",
      secondary_gold: "#D79A2B",
      secondary_light_gray: "#F2F2F2",
      base_black: "#0B0A0A",
      neutral100: "#E4E4E4",
      neutral300: "#BAB8B8",
      neutral400: "#A5A1A1",
      neutral500: "#908B8B",
      neutral600: "#7A7474",
      neutral700: "#655F5F",
      neutral800: "#4E4949",
      neutral900: "#383434",
      neutral1000: "#221F1F",
      border: "#EDEDED",

      g1: "linear-gradient(158deg, #F5F5F5 0%, #F6F6F6 98.87%)",
    },
  },
});
