import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import BgGradientLines from "../../assets/images/bg-lines.png";
import GoldLine from "../../svgs/GoldLine";

function BadgesStatus() {
  return (
    <CustomCard maxW="533px">
      <Flex gap="16px" align="center">
        <CustomText text="Badges" wrap="nowrap" color="yess.base_black" />
        <Divider bg="#F2F2F2" />
      </Flex>
      <Flex flexDir="column" gap="8px" mt="24px">
        {BADGES.map((badge, index) => (
          <Flex key={index} gap="12px" align="flex-start">
            <Box maxW="95px" w="100%">
              <CustomText
                text={badge.title}
                color="yess.neutral700"
                size="10px"
                align="end"
                mt="3px"
              />
            </Box>
            <Flex
              w="100%"
              h="24px"
              borderRadius="4px"
              background={`url(${BgGradientLines}), rgba(242, 242, 242, 0.75)`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              //   backgroundPosition="right"
            >
              <Flex
                p="4px"
                bg="#e9e9e9"
                w={badge.value}
                h="24px"
                borderRadius="4px"
                zIndex="10"
              >
                <Box bg="#387DF5" borderRadius="4px" w="100%" />
              </Flex>
              <Box mt="5px" ml="-2px" zIndex="1">
                <GoldLine />
              </Box>
            </Flex>
            <Box maxW="22px" w="100%">
              <CustomText
                text={badge.value}
                color="yess.neutral700"
                size="10px"
                mt="3px"
              />
            </Box>
          </Flex>
        ))}
      </Flex>
    </CustomCard>
  );
}

const BADGES = [
  {
    title: "VIP",
    value: "54%",
  },
  {
    title: "Delegation",
    value: "27%",
  },
  {
    title: "Organization",
    value: "54%",
  },
  {
    title: "Exhibitor",
    value: "24%",
  },
  {
    title: "Ministry of Defence",
    value: "54%",
  },
  {
    title: "Contractor",
    value: "73%",
  },
  {
    title: "Media",
    value: "32%",
  },
];

export default BadgesStatus;
