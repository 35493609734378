import {
  Avatar,
  Checkbox,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import EyeIcon from "../../svgs/EyeIcon";
import MailIcon from "../../svgs/MailIcon";
import EditIcon from "../../svgs/EditIcon";
import ArrowLeft from "../../svgs/ArrowLeft";
import ArrowRight from "../../svgs/ArrowRight";
import { IconPrinter, IconX } from "@tabler/icons-react";
import InviteCard from "../../components/cards/InviteCard";
import { useSelector } from "react-redux";

function BadgeTable({ selected, setSelected, search }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { unBadgeVisitors } = useSelector((state: any) => state.visitors);

  const [selectedBadge, setSelectedBadge] = useState(null);

  return (
    <Flex mt="16px" gap="20px" flexDir="column">
      <TableContainer overflowY="auto">
        <Table>
          <Thead>
            <Tr>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopLeftRadius="12px"
                w="20px"
              >
                <Checkbox
                  isChecked={selected?.length === unBadgeVisitors?.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelected(
                        unBadgeVisitors
                          ?.filter((exhi: any) => !exhi?.badge)
                          ?.map((exhi: any) => exhi.docId)
                      );
                    } else {
                      setSelected([]);
                    }
                  }}
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Badge No"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Attendee Info"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Type"
                  size="12px"
                  color="yess.neutral1000"
                  align="center"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Issue Date"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Valid for"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th px="20px" borderBottom="1px solid #CFCECE" bg="#F2F2F2">
                <CustomText
                  text="Status"
                  size="12px"
                  align="center"
                  color="yess.neutral1000"
                />
              </Th>
              <Th
                px="20px"
                borderBottom="1px solid #CFCECE"
                bg="#F2F2F2"
                borderTopRightRadius="12px"
              >
                <CustomText
                  text="Action"
                  size="12px"
                  color="yess.neutral1000"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {unBadgeVisitors
              // ?.sort((a: any, b: any) => a.docId - b.docId)
              ?.filter(
                (exhi: any) =>
                  exhi?.badge?.type
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) || exhi?.personalInfo?.name
              )
              ?.map((exhi: any, idx: number) => (
                <Tr key={idx}>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 20px" w="20px">
                    <Checkbox
                      isChecked={selected?.includes(exhi.docId)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected([...selected, exhi.docId]);
                        } else {
                          setSelected(
                            selected?.filter(
                              (docId: any) => docId !== exhi.docId
                            )
                          );
                        }
                      }}
                      display={exhi?.badge ? "none" : "block"}
                    />
                  </Td>
                  <Td borderLeft="1px solid #F2F2F2" p="16px 20px">
                    <CustomText
                      text={exhi?.badge?.badgeNo}
                      size="14px"
                      align="center"
                      color="yess.neutral800"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="6px 20px">
                    <Flex gap="10px" py="10px" align="center">
                      <Avatar size="sm" />
                      <Flex flexDir="column" gap="4px">
                        <CustomText
                          text={exhi?.personalInfo?.name}
                          color="yess.neutral1000"
                        />
                        <CustomText
                          text={exhi?.personalInfo?.email}
                          color="yess.neutral500"
                          size="12px"
                        />
                      </Flex>
                    </Flex>
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                    <CustomText
                      text={exhi?.badge?.type}
                      size="14px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                    <CustomText
                      text={exhi?.badge?.issueDate || "--"}
                      size="14px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                    <CustomText
                      text={exhi?.badge?.validFor}
                      size="14px"
                      color="yess.neutral800"
                      align="center"
                    />
                  </Td>
                  <Td borderLeft="1px solid #f2f2f2" p="16px 20px">
                    <Badge status={exhi?.badge?.status || exhi?.status} />
                  </Td>
                  <Td
                    borderLeft="1px solid #f2f2f2"
                    borderRight="1px solid #f2f2f2"
                    p="16px 20px"
                  >
                    <Flex align="center" gap="6px">
                      <ActionButton
                        icon={<EyeIcon />}
                        onClick={() => {
                          onOpen();
                          setSelectedBadge(exhi);
                        }}
                      />
                      <ActionButton icon={<MailIcon />} />
                      <ActionButton icon={<IconPrinter size="14px" />} />
                      <ActionButton
                        bg="yess.secondary_gold"
                        icon={<EditIcon />}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align="center" gap="16px" justify="end">
        <CustomText
          text={`1-${unBadgeVisitors?.length} of ${unBadgeVisitors?.length}`}
          size="13px"
          color="yess.neutral300"
        />
        <Flex align="center" gap="2px">
          <PaginationButton icon={<ArrowLeft />} />
          <PaginationButton text="1" bg="yess.secondary_gold" />
          <PaginationButton icon={<ArrowRight />} />
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="450px" borderRadius="16px">
          <Flex
            p="24px"
            align="center"
            justify="space-between"
            borderBottom="1px solid #CFCECE"
          >
            <CustomText text="Badge" color="yess.base_black" size="20px" />
            <IconX color="#000" onClick={onClose} />
          </Flex>
          <Flex flexDir="column" gap="24px" p="24px">
            <InviteCard info={selectedBadge} />
          </Flex>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

const ActionButton = ({ icon, bg, onClick }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="32px"
      w="32px"
      bg={bg || "yess.secondary_light_gray"}
      borderRadius="8px"
      onClick={onClick}
    >
      {icon}
    </Flex>
  );
};

const Badge = ({ status }: any) => {
  const getBgColor = () => {
    switch (status) {
      case "Active":
        return "#F1FFFC";
      case "Expired":
        return "#FFE9EB";
      case "Pending":
        return "#FFF9F1";
      default:
        return "yess.secondary_light_gray";
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case "Active":
        return "#2EC19E";
      case "Expired":
        return "#ED3D4E";
      case "Pending":
        return "#EDA145";
      default:
        return "yess.base_black";
    }
  };

  return (
    <Flex
      align="center"
      justify="center"
      w="98px"
      h="30px"
      borderRadius="99px"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      mx="auto"
    >
      <CustomText text={status} size="12px" color={getBorderColor()} />
    </Flex>
  );
};

const PaginationButton = ({ text, bg, icon }: any) => {
  return (
    <Flex
      align="center"
      justify="center"
      h="30px"
      w="30px"
      bg={bg || "#fff"}
      borderRadius="6px"
    >
      {icon && icon}
      {text && (
        <CustomText
          text={text}
          size="14px"
          weight="500"
          color={bg ? "#fff" : "yess.neutral300"}
        />
      )}
    </Flex>
  );
};

export default BadgeTable;
