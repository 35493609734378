import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomHeading from "../text/CustomHeading";
import ThemeIcon from "../../svgs/ThemeIcon";
import IconNotification from "../../svgs/IconNotification";
import CustomText from "../text/CustomText";
import { IconChevronDown, IconLogout } from "@tabler/icons-react";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSuperExhibitor } from "../../redux/actions/exhibitors.actions";
import { logout } from "../../redux/actions/auth.actions";

function Navbar() {
  const dispatch: any = useDispatch();

  const { superExhibitor } = useSelector((state: any) => state.exhibitors);

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [loggedInUser] = useState(() => {
    const user: any = localStorage.getItem("user");
    return JSON.parse(user);
  });

  useEffect(() => {
    const exhibitorId = localStorage.getItem("exhibitorId");
    dispatch(getSuperExhibitor(exhibitorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      align="center"
      justify="space-between"
      p="24px"
      borderBottom="1px solid"
      borderColor="yess.border"
      h="88px"
      justifyContent="space-between"
    >
      <CustomHeading
        text={`${superExhibitor?.companyInfo?.name} Management Dashboard`}
        family="aeonik-regular"
        size="20px"
      />
      <Flex align="center" gap="16px">
        <Flex
          h="40px"
          w="40px"
          borderRadius="8px"
          border="1px solid"
          borderColor="yess.border"
          align="center"
          justify="center"
        >
          <ThemeIcon />
        </Flex>
        <Flex
          h="40px"
          w="40px"
          borderRadius="8px"
          border="1px solid"
          borderColor="yess.border"
          align="center"
          justify="center"
        >
          <IconNotification />
        </Flex>
        <Box pos="relative">
          <Flex
            gap="8px"
            align="center"
            cursor="pointer"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <Image
              src="https://bit.ly/dan-abramov"
              boxSize="40px"
              borderRadius="8px"
            />
            <Flex flexDir="column">
              <CustomText
                text={superExhibitor?.companyInfo?.name || "Admin"}
                color="yess.base_black"
              />
              <CustomText
                text={superExhibitor?.companyInfo?.email || loggedInUser?.email}
                color="yess.neutral500"
                size="14px"
              />
            </Flex>
            <IconChevronDown color="#292D32" size="20px" />
          </Flex>
          <OutsideClickHandler
            isOpen={isMenuOpen}
            onClose={() => {
              setIsMenuOpen(false);
            }}
          >
            <Flex
              bg="#f2f2f2"
              p="16px 24px"
              borderRadius="12px"
              gap="12px"
              align="center"
              pos="absolute"
              w="100%"
              mt="5px"
              cursor="pointer"
              onClick={() => {
                dispatch(logout());
                localStorage.clear();
                navigate("/auth/login");
              }}
            >
              <IconLogout color="#292D32" size="20px" />
              <CustomText text="Logout" color="yess.error" cursor="pointer" />
            </Flex>
          </OutsideClickHandler>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Navbar;
