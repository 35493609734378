import { Box } from "@chakra-ui/react";
import React from "react";

function CustomCard({ children, minH, minW, w, maxW }: any) {
  return (
    <Box
      p="24px"
      borderRadius="12px"
      border="1px solid"
      borderColor="yess.border"
      bg="#fff"
      minH={minH}
      minW={minW}
      w={w || "100%"}
      maxW={maxW}
    >
      {children}
    </Box>
  );
}

export default CustomCard;
