import React from "react";

function VisitorAnalyticsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M22.6 17.2624C21.8936 19.5064 20.4899 21.4667 18.5929 22.8583C16.696 24.2498 14.4046 25.0001 12.052 25C9.12084 25 6.30971 23.8356 4.23706 21.7629C2.16441 19.6903 1 16.8792 1 13.948C0.99994 11.5954 1.75021 9.30403 3.14174 7.40707C4.53328 5.51011 6.49355 4.1064 8.73761 3.39997"
        stroke="black"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M24.8954 10.5364C24.2641 8.28734 23.0649 6.23848 21.4131 4.58669C19.7613 2.93491 17.7125 1.73572 15.4634 1.10437C13.4906 0.553568 11.7998 2.26477 11.7998 4.31197V12.3448C11.7998 13.3684 12.6302 14.1988 13.6538 14.1988H21.6866C23.735 14.1988 25.4462 12.508 24.8954 10.5364Z"
        stroke="black"
        stroke-width="1.3"
      />
    </svg>
  );
}

export default VisitorAnalyticsIcon;
