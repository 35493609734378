import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, firestore } from "../../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";

export const getAllVisitors = (id: any) => async (dispatch: any) => {
  const q = query(
    collection(firestore, "visitors"),
    where("exhibitorId", "==", id)
  );
  onSnapshot(q, (querySnapshot) => {
    const visitors: any = [];
    querySnapshot.forEach((doc) => {
      visitors.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_VISITORS",
      payload: visitors,
    });
  });
};

export const getUnBadgeVisitors = (id: any) => async (dispatch: any) => {
  const q = query(
    collection(firestore, "visitors"),
    where("exhibitorId", "==", id)
    // where("badgeAssigned", "==", false)
  );
  onSnapshot(q, (querySnapshot) => {
    const visitors: any = [];
    querySnapshot.forEach((doc) => {
      visitors.push({ docId: doc.id, ...doc.data() });
    });
    dispatch({
      type: "FETCH_UNBADGE_VISITORS",
      payload: visitors,
    });
  });
};

export const updateExhibitorQuota = (id: any) => async (dispatch: any) => {
  // Get reference to the Firestore document
  const docRef = doc(firestore, "exhibitors", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // Extract existing data from the document
    const data = docSnap.data();

    // Update the utilizedBadges field
    await updateDoc(docRef, {
      utilizedBadges: Number(data.utilizedBadges) + 1,
    });
  } else {
    console.error("Document not found!");
  }
};

export const assignBadge =
  (id: any, payload: any, exhibitorId: any, onSuccess: any) =>
  async (dispatch: any) => {
    try {
      updateDoc(doc(firestore, "visitors", id), payload).then((res) => {
        dispatch(updateExhibitorQuota(exhibitorId));
        onSuccess();
        toast.success("Badge Assigned Successfully");
        return;
      });
    } catch (e: any) {
      toast.warning(e.repsonse.message);
    }
  };

export const inviteVisitor =
  (creds: any, password: any, onSuccess: any) => async (dispatch: any) => {
    createUserWithEmailAndPassword(auth, creds?.personalInfo?.email, password)
      .then((data) => {
        try {
          setDoc(doc(firestore, "visitors", data.user.uid), creds)
            .then((res) => {
              dispatch({
                type: "REGISTER_SUCCESS",
              });
              toast.success("Visitor Invited Successfully");
              onSuccess();
            })
            .catch((error) => {
              dispatch({
                type: "REGISTER_FAIL",
              });
              toast.warning(error.message);
            });
        } catch (e: any) {
          toast.warning(e.response.message);
        }
      })
      .catch((error) => {
        dispatch({
          type: "REGISTER_FAIL",
        });
        toast.warning(error.message);
      });
  };

export const deleteVisitor = (id: any) => async (dispatch: any) => {
  try {
    deleteDoc(doc(firestore, "visitors", id)).then((res) => {
      return;
    });
  } catch (e) {}
};
