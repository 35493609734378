import React from "react";

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.0899 4.80779V7.43454H1.90263C1.75162 7.43454 1.6068 7.49452 1.50002 7.6013C1.39324 7.70808 1.33325 7.85291 1.33325 8.00392C1.33325 8.15493 1.39324 8.29975 1.50002 8.40653C1.6068 8.51331 1.75162 8.5733 1.90263 8.5733H10.0899V11.1925C10.0891 11.2641 10.1098 11.3343 10.1494 11.394C10.1891 11.4537 10.2457 11.5001 10.3121 11.5271C10.3784 11.5541 10.4514 11.5606 10.5214 11.5456C10.5915 11.5306 10.6554 11.4948 10.7048 11.443L14.56 8.25444C14.5938 8.22124 14.6206 8.18165 14.6389 8.13799C14.6572 8.09433 14.6666 8.04746 14.6666 8.00012C14.6666 7.95278 14.6572 7.90591 14.6389 7.86225C14.6206 7.81859 14.5938 7.779 14.56 7.7458L10.7048 4.55726C10.6554 4.50543 10.5915 4.46969 10.5214 4.45469C10.4514 4.43969 10.3784 4.44612 10.3121 4.47315C10.2457 4.50018 10.1891 4.54655 10.1494 4.60624C10.1098 4.66592 10.0891 4.73615 10.0899 4.80779Z"
        fill="#D79A2B"
      />
    </svg>
  );
}

export default ArrowRight;
