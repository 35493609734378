import React from "react";

function ThemeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 23.125C19.4873 23.125 23.125 19.4873 23.125 15C23.125 10.5127 19.4873 6.875 15 6.875C10.5127 6.875 6.875 10.5127 6.875 15C6.875 19.4873 10.5127 23.125 15 23.125Z"
        stroke="#221F1F"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.925 23.925L23.7625 23.7625M23.7625 6.2375L23.925 6.075L23.7625 6.2375ZM6.075 23.925L6.2375 23.7625L6.075 23.925ZM15 2.6V2.5V2.6ZM15 27.5V27.4V27.5ZM2.6 15H2.5H2.6ZM27.5 15H27.4H27.5ZM6.2375 6.2375L6.075 6.075L6.2375 6.2375Z"
        stroke="#221F1F"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ThemeIcon;
