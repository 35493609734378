import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import VisitorAnalyticsIcon from "../../svgs/VisitorAnalyticsIcon";
import BgGradientLines from "../../assets/images/bg-lines2.png";

function VisitorAnalytics() {
  return (
    <CustomCard>
      <Flex flexDir="column" justify="space-between" minH="182px">
        <Flex justify="space-between" align="center" gap="24px">
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              h="40px"
              w="40px"
              bg={"yess.secondary_light_gray"}
              borderRadius="8px"
            >
              <VisitorAnalyticsIcon />
            </Flex>
            <CustomText
              text="Visitor Analytics"
              color="yess.base_black"
              size="18px"
            />
          </Flex>
          <CustomText text="8,654" size="40px" color="yess.base_black" />
        </Flex>
        <Flex gap="4px" align="center">
          <Flex flexDir="column" w="23%">
            <CustomText text="Accepted" size="12px" color="#2EC19E" />
            <CustomText text="1874" size="19px" color="#1A1A1A" />
            <Box w="1px" h="32px" bg="#f2f2f2" my="3px" />
            <Box
              background={`url(${BgGradientLines}), #2EC19E`}
              bgRepeat="no-repeat"
              bgSize="cover"
              w="100%"
              h="32px"
              borderRadius="6px"
            />
          </Flex>
          <Flex flexDir="column" w="46%">
            <CustomText text="Sent" size="12px" color="#D79A2B" />
            <CustomText text="1874" size="19px" color="#1A1A1A" />
            <Box w="1px" h="32px" bg="#f2f2f2" my="3px" />
            <Box
              background={`url(${BgGradientLines}), #D79A2B`}
              bgRepeat="no-repeat"
              bgSize="cover"
              w="100%"
              h="32px"
              borderRadius="6px"
            />
          </Flex>
          <Flex flexDir="column" w="32%">
            <CustomText text="Pending" size="12px" color="#387DF5" />
            <CustomText text="3,748" size="19px" color="#1A1A1A" />
            <Box w="1px" h="32px" bg="#f2f2f2" my="3px" />
            <Box
              background={`url(${BgGradientLines}), #387DF5`}
              bgRepeat="no-repeat"
              bgSize="cover"
              w="100%"
              h="32px"
              borderRadius="6px"
            />
          </Flex>
        </Flex>
      </Flex>
    </CustomCard>
  );
}

export default VisitorAnalytics;
