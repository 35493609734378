import Login from "../pages/auth/Login";
import Badges from "../pages/badges";
import Dashboard from "../pages/dashboard/Dashboard";
import Exhibitors from "../pages/exhibitors";
import CreateExhibitor from "../pages/exhibitors/CreateExhibitor";
import Visitors from "../pages/visitors";
import VisitorDetails from "../pages/visitors/VisitorDetails";

export const routes = [
  {
    path: "login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/",
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/sub-exhibitors",
    component: Exhibitors,
    isPrivate: true,
  },
  {
    path: "/sub-exhibitors/create-sub-exhibitor",
    component: CreateExhibitor,
    isPrivate: true,
  },
  {
    path: "/invite-visitors",
    component: Visitors,
    isPrivate: true,
  },
  {
    path: "/visitor-details/:id",
    component: VisitorDetails,
    isPrivate: true,
  },
  {
    path: "/badges",
    component: Badges,
    isPrivate: true,
  },
];
