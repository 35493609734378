import React from "react";
import CustomCard from "../../components/cards/CustomCard";
import { Box, Divider, Flex } from "@chakra-ui/react";
import CustomText from "../../components/text/CustomText";
import BgGradientLines from "../../assets/images/bg-lines.png";
import GoldLine from "../../svgs/GoldLine";

function VettingStatus() {
  return (
    <CustomCard>
      <Flex gap="16px" align="center">
        <CustomText
          text="Vetting Status"
          wrap="nowrap"
          color="yess.base_black"
        />
        <Divider bg="#F2F2F2" />
      </Flex>
      <Flex flexDir="column" gap="8px" mt="24px">
        {VETTTING_STATUS.map((status, index) => (
          <Flex key={index} gap="12px" align="flex-start">
            <Box maxW="70px" w="100%">
              <CustomText
                text={status.title}
                color="yess.neutral700"
                size="10px"
                align="end"
                mt="3px"
              />
            </Box>
            <Flex
              w="100%"
              h="24px"
              borderRadius="4px"
              background={`url(${BgGradientLines}), rgba(242, 242, 242, 0.75)`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              //   backgroundPosition="right"
            >
              <Flex
                p="4px"
                bg="#e9e9e9"
                w={status.value}
                h="24px"
                borderRadius="4px"
                zIndex="10"
              >
                <Box bg="#387DF5" borderRadius="4px" w="100%" />
              </Flex>
              <Box mt="5px" ml="-2px" zIndex="1">
                <GoldLine />
              </Box>
            </Flex>
            <Box maxW="22px" w="100%">
              <CustomText
                text={status.value}
                color="yess.neutral700"
                size="10px"
                mt="3px"
              />
            </Box>
          </Flex>
        ))}
      </Flex>
    </CustomCard>
  );
}

const VETTTING_STATUS = [
  {
    title: "On hold",
    value: "54%",
  },
  {
    title: "Pending",
    value: "27%",
  },
  {
    title: "Info Required",
    value: "54%",
  },
  {
    title: "Approved",
    value: "24%",
  },
  {
    title: "Resubmission",
    value: "54%",
  },
  {
    title: "Rejected",
    value: "73%",
  },
];

export default VettingStatus;
