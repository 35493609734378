import React from "react";

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.33374 5.66585C1.33374 3.33252 2.66707 2.33252 4.66707 2.33252H11.3337C13.3337 2.33252 14.6671 3.33252 14.6671 5.66585V10.3325C14.6671 12.6659 13.3337 13.6659 11.3337 13.6659H4.66707"
        stroke="#4E4949"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3329 6L9.24626 7.66667C8.55959 8.21333 7.43292 8.21333 6.74626 7.66667L4.66626 6"
        stroke="#4E4949"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33374 11H5.33374"
        stroke="#4E4949"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33374 8.33252H3.33374"
        stroke="#4E4949"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default MailIcon;
